define("discourse/plugins/dsc-tcc-level-view/discourse/initializers/dsc-tcc-level-view", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-unused-vars */
  /* eslint-disable no-console */
  /* eslint-disable no-alert */

  const h = require("virtual-dom").h;
  var _default = _exports.default = {
    name: "dsc-tcc-level-view",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        // function getCategory() {
        //     return ajax(`/categories.json`, {
        //         type: "GET",
        //     });
        // }

        // function getLatestTopic() {
        //     return ajax(`/latest.json`, {
        //         type: "GET",
        //     });
        // }

        function getCategorySlug(slug, id) {
          return (0, _ajax.ajax)(`/c/${slug}/${id}.json`, {
            type: "GET"
          });
        }
        const currentUser = api.getCurrentUser();
        // console.log("Current User", currentUser);

        const category_site_settings = api.container.lookup("site-settings:main");
        const category_id_site_settings = category_site_settings.categories_id;
        const array_of_category_id_site_settings = category_id_site_settings.split("|");
        // console.log("array_of_category_id_site_settings", array_of_category_id_site_settings);

        const category_slug_site_settings = category_site_settings.categories_slug;
        const array_of_category_slug_site_settings = category_slug_site_settings.split("|");
        // console.log("array_of_category_slug_site_settings", array_of_category_slug_site_settings);

        if (currentUser !== null) {
          const currentUserTrustLevel = currentUser.trust_level;
          // console.log("Current user Trust Level", typeof (currentUserTrustLevel), currentUserTrustLevel);

          array_of_category_id_site_settings.forEach((id, index) => {
            if (id !== "0") {
              let slug = array_of_category_slug_site_settings[index];
              // console.log(id, array_of_category_slug_site_settings[index]);
              getCategorySlug(slug, id).then(cat => {
                // console.log("cat", Object.keys(cat).length, cat);
                const category_topics_list = cat.topic_list.topics;
                // console.log("category_topics_list", category_topics_list);
                category_topics_list.forEach(topic_id => {
                  // console.log("Topic id from Category", typeof (topic_id.id), topic_id.id);
                  if (currentUserTrustLevel === 0) {
                    api.onPageChange((url, title) => {
                      const router = api.container.lookup("service:router");
                      const router_name = router.currentRoute.name;
                      console.log("Get Router Name", router_name);
                      if (router_name === "topic.fromParamsNear" || router_name === "topic.fromParams") {
                        let postStream = document.querySelector('.post-stream');
                        // const topicArea = document.querySelector('.topic-area');
                        console.log("post Stream", postStream);
                        // console.log("topicArea", topicArea);
                        if (postStream !== null) {
                          const firstTopicPost = postStream.querySelector('[data-topic-id]');
                          if (firstTopicPost) {
                            const topicId = firstTopicPost.getAttribute('data-topic-id');
                            console.log("First Topic ID:", typeof topicId, topicId);
                            if (Number(topicId) === topic_id.id) {
                              postStream.classList.add("hidden");
                              //alert(`Alert: In order to view the posts upgrade to Mastermind Membership`);
                              // setTimeout(() => {
                              //     postStream.style.display = "none"; // Fully remove div from view
                              //     alert(`Alert: In order to view the posts upgrade to Mastermind Membership`);
                              // }, 200);
                              showMessage("Alert: To view the posts upgrade to Mastermind Membership", "posts-wrapper");
                              setTimeout(() => showMessage("Alert: In order to view the posts upgrade to Mastermind Membership", "posts-wrapper"), 200);
                            }
                          } else {
                            console.log("No element with data-topic-id found.");
                          }
                        }
                      }
                    });
                  }
                });
              });
            }
          });
          api.decorateWidget('post:before', helper => {
            //console.log("Helper", helper);
            console.log("Helper", helper.attrs.topicId, helper.attrs.trustLevel);
            document.addEventListener("DOMContentLoaded", function () {
              let postStream = document.querySelectorAll('.post-stream');
              // console.log("postStream", postStream);
              // if (currentUserTrustLevel === 0) {
              //     console.log("add Event listener");
              // }
              array_of_category_id_site_settings.forEach((id, index) => {
                if (id !== "0") {
                  let slug1 = array_of_category_slug_site_settings[index];
                  // console.log(id, array_of_category_slug_site_settings[index]);
                  getCategorySlug(slug1, id).then(cat => {
                    // console.log("cat", Object.keys(cat).length, cat);
                    const category_topics_list1 = cat.topic_list.topics;
                    // console.log("category_topics_list", category_topics_list);
                    category_topics_list1.forEach(topic_id => {
                      // console.log("Topic id from Category", typeof (topic_id.id), topic_id.id);
                      if (currentUserTrustLevel === 0) {
                        //api.onPageChange((url, title) => {
                        const router1 = api.container.lookup("service:router");
                        const router_name1 = router1.currentRoute.name;
                        console.log("Get Router Name", router_name1);
                        if (router_name1 === "topic.fromParamsNear" || router_name1 === "topic.fromParams") {
                          let postStream1 = document.querySelector('.post-stream');
                          // const topicArea = document.querySelector('.topic-area');
                          console.log("post Stream1", postStream1);
                          // console.log("topicArea", topicArea);
                          if (postStream1 !== null) {
                            const firstTopicPost1 = postStream1.querySelector('[data-topic-id]');
                            if (firstTopicPost1) {
                              const topicId1 = firstTopicPost1.getAttribute('data-topic-id');
                              console.log("First Topic ID:", typeof topicId1, topicId1);
                              if (Number(topicId1) === topic_id.id) {
                                postStream1.classList.add("hidden");
                                //alert(`Alert: In order to view the posts upgrade to Mastermind Membership`);
                                // setTimeout(() => {
                                //     postStream1.style.display = "none"; // Fully remove div from view
                                //     alert(`Alert: In order to view the posts upgrade to Mastermind Membership`);
                                // }, 200);
                                showMessage("Alert: To view the posts upgrade to Mastermind Membership", "posts-wrapper");
                                setTimeout(() => showMessage("Alert: In order to view the posts upgrade to Mastermind Membership", "posts-wrapper"), 200);
                              }
                            } else {
                              console.log("No element with data-topic-id found.");
                            }
                          }
                        }
                        //});
                      }
                    });
                  });
                }
              });
            });
          });

          //////////////
          //////////////
        }
      });
    }
  }; // let messageShown = false;
  // function showMessage(message, divClass) {
  //     if (!messageShown) {
  //         const targetDiv = document.querySelector(`.${divClass}`);
  //         console.log("Target", targetDiv);
  //         let messageElement = document.createElement("div");
  //         messageElement.classList.add("message");
  //         messageElement.textContent = message;
  //         //document.body.appendChild(messageElement);
  //         targetDiv.appendChild(messageElement);
  //         messageShown = true;
  //     }
  // }
  function showMessage(message, containerClass) {
    let targetContainer = document.querySelector(`.${containerClass}`);
    if (!targetContainer) {
      targetContainer = document.createElement("div");
      targetContainer.classList.add(containerClass);
      document.body.appendChild(targetContainer);
    } else {
      targetContainer.innerHTML = ''; //Clear any previous messages in the target container
    }
    let messageElement = document.createElement("div");
    messageElement.classList.add("message");
    messageElement.textContent = message;
    targetContainer.appendChild(messageElement);
  }
});